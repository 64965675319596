import { Controller } from "stimulus"
// Import UJS so we can access the Rails.ajax method
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "country", "state", "city"]
	static values = {
		stateUrl: String,
		cityUrl: String
	}

  connect() {
		//this.populate_state()
  }

	populate_state(){
		const url = this.stateUrlValue
		const el = this.stateTarget 
		const message = "Escolha seu Estado"
		const data = {country: this.countryTarget.value}
		populate_select_options(url, data, el, message)
	}

	populate_city(){
		const url = this.cityUrlValue
		const el = this.cityTarget 
		const message = "Escolha sua Cidade"
		const data = {state: this.stateTarget.value, country: this.countryTarget.value }
		populate_select_options(url, data, el, message)
	}
}

function populate_select_options(url, data, el, message){
	Rails.ajax({
		type: "GET", 
		url: url,
		data: new URLSearchParams(data).toString(),
		success: function(response) {
			el.innerHTML = "";
			var listitems = [];
			listitems += `<option value="">${message}</option>`;
			//convert key, value objects into list to append to html
			$.each(response, function(key, value){
				// listitems += "<option value=" + value + " key=" + key + ">" + value + "</option>"
				listitems += `<option value="${key}">${value}</option>`;
			})
			el.innerHTML = listitems;
		},
		error: function(response) {console.log(response)}
	})


}
