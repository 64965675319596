import { Controller } from "stimulus"
// Import UJS so we can access the Rails.ajax method
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "container" ]

	show_form(){
		this.get_partial()
	}

	hide_form(){
	}
	update_partial(data){
		const container =	document.getElementById(this.data.get("item-dom-id"))
		container.innerHTML = data.html
	}
	get_partial(){
		let self = this
		Rails.ajax({
			type: "GET", 
			url: this.data.get("partial-url"),
			success: function(response) {self.update_partial(response)},
			error: function(response) {console.log(response)}
		})
	}
}
