import tippy from 'tippy.js';
import { Controller } from "stimulus";

export class TippyController extends Controller {
  static targets = ["content", "trigger"]
	static values = { allowHtml: Boolean, interactive: Boolean, theme: String, placement: String, triggerOption: String }

  initialize() {
		this.trigger = this.getTrigger();
		this.content = this.getContent();
		this.allowHtml = this.getAllowHTML();
		this.interactive = this.getInteractive();
		this.theme = this.getTheme();
		this.placement = this.getPlacement();
		this.triggerOption = this.getTriggerOption();
		this.tippy = this.initTippy();
  }

	initTippy(){
		this.popup = tippy(this.trigger, {
			content: this.content.innerHTML,
			theme: this.theme,
			allowHTML: this.allowHtml,
			placement: this.placement,
			interactive: this.interactive,
			trigger: this.triggerOption,
		});
	}

	getAllowHTML(){
		return this.hasAllowHtmlValue ? this.allowHtmlValue : false
	}

	getContent(){
		return this.hasContentTarget ? this.contentTarget : false
	}

	getInteractive(){
		return this.hasInteractiveValue ? this.interactiveValue : false
	}

	getPlacement(){
		return this.hasPlacementValue ? this.placementValue : 'top'
	}

	getTheme(){
		return this.hasThemeValue ? this.themeValue : ''
	}

	getTrigger(){
		return this.hasTriggerTarget ? this.triggerTarget : this.element
	}

	getTriggerOption(){
		return this.hasTriggerOptionValue ? this.triggerOptionValue : 'mouseenter focus'
	}
}
