import { Controller } from 'stimulus'
//import $ from 'jquery'
import 'parsleyjs'
import 'parsleyjs/dist/i18n/pt-br'

export default class extends Controller {
  connect() {
    $(this.element).parsley({
      errorClass: 'has-danger',
      successClass: 'has-success',
      errorsWrapper: '<div class="red"></div>',
      errorTemplate: '<span class="form-text text-danger"></span>',
      classHandler(ParsleyField) {
        return ParsleyField.$element.parent()
      }
    })
  }
}
