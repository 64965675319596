import { Controller } from "stimulus"
//import $ from 'jquery'
import moment from 'moment'

export default class extends Controller {

	static values = {datetime: String}

  initialize () {
    this.isValid = true
  }

  connect() {
		this.load();
  }

	load(){
		const datetime = this.datetimeValue;
		const date = Date.parse(datetime);
    if (Number.isNaN(date)) {
      this.isValid = false
      console.error(
        `[stimulus-human-dates] Value given in 'data-timeago-datetime' is not a valid date (${datetime}). Please provide a ISO 8601 compatible datetime string. Displaying given value instead.`
      )
    }
		this.element.dateTime = datetime;
    this.element.innerHTML = `(${moment(date).locale("pt").fromNow()})`;
		//console.log(this.isValid);
	}
}
