import { Controller } from "stimulus"
import Swiper, { Pagination, Navigation, Autoplay } from 'swiper'
Swiper.use([Pagination, Navigation])

export default class extends Controller {
	static targets = ["pagination"]
  connect() {
    const swiper = new Swiper(`#${this.element.id}`, {
      spaceBetween: 30,
      centeredSlides: true,
      pagination: {
        el: `.${this.paginationTarget.classList[0]}`,
        clickable: true,
      }
    } );
  }
}
