import typeahead from "corejs-typeahead/dist/typeahead.jquery.min.js";
import Bloodhound from "corejs-typeahead/dist/bloodhound.min.js";

$(document).on("turbolinks:load", function(){
	applyTypeaheadToSearch();
	applySidebar();
	showSidebarAfterLoad();
});


function applySidebar(){
	$( "#search_sidebar" ).simplerSidebar( {
		selectors: {
			trigger: ".open_search_sidebar",
			quitter: ".quit-sidebar",
		},
		sidebar: {
			width: 350,
		},
	});

	$( "#sidebar" ).simplerSidebar( {
		selectors: {
			trigger: "#open_sidebar",
			quitter: "#close_sidebar",
		},
		sidebar: {
			width: 350,
		},
		align: "left"
	});

}

function showSidebarAfterLoad(){
	$('#sidebar, #search_sidebar').removeAttr('hidden');
}

function applyTypeaheadToSearch(){
	let suggestionTemplate = function(data){
		return `
			<article class="relative w-100">
				<a class="link dt w-100 bb b--black-10 pb2 mt2 dim blue" href="opportunities/${data.id}" >
					<div class="dtc v-top pl2">
						<h1 class="f6 f5-ns fw6 lh-title black mv0">${data.name}</h1>
						<p class="f6 fw4 mt2 mb0 black-60">${data.summary}</p>
						<dl class="mt2 f6">
							<dt class="clip">End Date</dt>
							<dd class="ml0">
								<i class="fa fa-calendar" aria-hidden="true"></i>
								<span data-controller="human-dates" data-target="human-dates.output" class="cronogram" data-human-dates-datetime="${data.end_date}"></span>
							</dd>
						</dl>
					</div>
				</a>
			</article>
		`;
	}

	let opportunities = new Bloodhound({
		datumTokenizer: Bloodhound.tokenizers.whitespace,
		queryTokenizer: Bloodhound.tokenizers.whitespace,
		remote: {
      url: "/opportunities/autocomplete?query=%QUERY",
      wildcard: "%QUERY"
		}
	});
	$("#query").typeahead(null, {
		limit: 10,
		source: opportunities,
		templates: {
			suggestion: function(data) {
				return suggestionTemplate(data)
			}
		}
	});
}
