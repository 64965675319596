import { Controller } from "stimulus"
// Import UJS so we can access the Rails.ajax method
import Rails from "@rails/ujs"

import Hammer from "hammerjs"

export default class extends Controller {
  static targets = [ "icon", "container" ]
	static values = { followUrl: String, unfollowUrl: String, following: Boolean }

	connect(){
		// Create a manager to manager the element
		const manager = new Hammer.Manager(this.element);

		// Create a recognizer
		const DoubleTap = new Hammer.Tap({
			event: 'doubletap',
			taps: 2
		});

		// Add the recognizer to the manager
		manager.add(DoubleTap);

		let self = this
		// Subscribe to desired event
		manager.on('doubletap', function(e) {
			self.toggle_follow(event)
		});
	}

	toggle_follow(event){
		event.preventDefault()
		this.followingValue ? this.unfollow_item() : this.follow_item()
		this.followingValue = !this.followingValue
	}

	follow_item(){
		let self = this
		Rails.ajax({
			type: "POST", 
			url: self.followUrlValue,
			success: function(response) {self.update_partial(response)},
			error: function(response) {console.log(response)}
		})
	}

	unfollow_item(){
		let self = this
		Rails.ajax({
			type: "DELETE", 
			url: self.unfollowUrlValue,
			success: function(response) {self.update_partial(response)},
			error: function(response) {console.log(response)}
		})

	}

	update_partial(data){
		const container =	this.containerTarget
		container.innerHTML = data.html
	}
}
