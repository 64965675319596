import { TippyController } from "./tippy_controller";

export default class extends TippyController {
	static targets = ["icon"]
	static values = { isOpen: Boolean }

	connect(){
		let instance = this.element._tippy;
		let self = this
		instance.setProps({
			onShow(instance) {
				self.toggleIsOpen();
			},
			onHidden(instance) {
				self.toggleIsOpen();
			},
		})
	}

	toggleIsOpen(){
		this.isOpenValue = !this.isOpenValue;
	}

	isOpenValueChanged(){
		this.isOpenValue ? this.iconTarget.classList.add("-rotate-90") : this.iconTarget.classList.remove("-rotate-90")
	}
}
