import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["source"]

	disconect(){
		clearTimeout(this.timeout)
	}
  copy() {
		if(this.sourceTarget.type==="hidden"){
			let value = this.sourceTarget.value;
			this.setClipboard(value)
		}else{
			this.sourceTarget.select();
			document.execCommand("copy");
		}
		this.showLoading()
		this.hideLoading()
  }

	setClipboard(value) {
		let tempInput = document.createElement("input");
		tempInput.style = "position: absolute; left: -1000px; top: -1000px";
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);
	}

	getSource(){
		this.hasSourceTarget ? this.sourceTarget : false
	}

	showLoading(){
		const html = '<i class="fa fa-clone fa-fw"></i><span class="">link copiado...</span>'
		this.setLoadingContainer(html)
	}

	hideLoading(){
		this.timeout = setTimeout(() => { this.setLoadingContainer("") }, 1500);
	}

	setLoadingContainer(html){
		document.getElementById("loading_container").innerHTML = html
	}

}
