import { Controller } from 'stimulus'
import * as Masonry from 'masonry-layout';
// Import UJS so we can access the Rails.ajax method
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['paging', 'container']
	static values = { loadContent: Boolean, nextPageUrl: String, useMasonry: Boolean }

	initialize(){
		let options = {
			rootMargin: '200px',
		}
		this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
	}

  connect() {
		this.useMasonryValue ? this.applyMasonry() : false
		this.hasPagingTarget ? this.intersectionObserver.observe(this.pagingTarget) : false;
  }

  disable() {
		this.intersectionObserver.unobserve(this.pagingTarget)
		clearTimeout(this.timeout)
  }

	processIntersectionEntries(entries){
		entries.forEach(entry => {
			(entry.isIntersecting && this.hasNextPageUrlValue) ? this.loadContentValue = true : this.loadContentValue = false
		})
	}

	applyMasonry(){
		this.msnry = new Masonry(this.containerTarget, {
			itemSelector: '.opportunity-card'
		})
	}

  loadNextPageContent() {
		clearTimeout(this.timeout)
		this.showLoading()
		let next_page_url = this.nextPageUrlValue
		let self = this
		Rails.ajax({
			type: "GET", 
			url: next_page_url,
			dataType: 'json',
			success: function(response) {self.updateContainer(response)},
			error: function(response) {console.log(response)}
		})
  }

	updateContainer(response){
		this.containerTarget.insertAdjacentHTML('beforeend',response.html)
		this.useMasonryValue ? this.applyMasonry() : false
		this.pagingTarget.innerHTML = response.paging	
		response.next_page_url != null ? this.nextPageUrlValue = response.next_page_url : delete(this.element.dataset.infiniteScrollingNextPageUrlValue)
	}

	showLoading(){
		const html = '<i class="fa fa-spinner fa-spin fa-2x fa-fw"></i><span class="sr-only">Loading...</span>'
		this.setLoadingContainer(html)
	}

	hideLoading(){
		this.timeout = setTimeout(() => { this.setLoadingContainer("") }, 500);
	}

	setLoadingContainer(html){
		document.getElementById("loading_container").innerHTML = html
	}

	loadContentValueChanged(){
		this.loadContentValue ? this.loadNextPageContent() : this.hideLoading()
	}
}
